<template lang="pug">
  // There should not be any style class used besides defined in this component
  // Because it should be environment-independent.
  #alert_app(v-if="id && active && urlNotExcluded")
    transition(name="fade-slide" mode="out-in")
      .sticky-alert(v-if="!dismissed" :class="'sticky-alert-' + variant" key="alert")
        .sticky-alert-container
          .sticky-alert-column
            .sticky-alert-content
              div
                i.fa.sticky-alert-fa-icon.flashing(v-if="variantFaIcon" :class="'fa-' + variantFaIcon")
                span.sticky-alert-svg.flashing(v-else v-html="svgData")

                span.sticky-alert-text {{text}}

              .sticky-alert-links
                div
                  a.sticky-alert-button(v-for="(button, i) in buttons" :href="button.url" target="_blank" :key="i") {{button.label}}

                a.sticky-alert-close(href="#" @click.prevent="toggleDismissed")
                  | Скрыть
                  i.fa.fa-angle-up.fa-fw
      .sticky-bookmark(v-else-if="false" @click="toggleDismissed" key="bookmark")
        .sticky-alert-container
          .sticky-alert-column
            .sticky-bookmark-icon(:class="'sticky-alert-' + variant")
              i.fa.sticky-alert-fa-icon(v-if="variantFaIcon" :class="'fa-' + variantFaIcon")
              span.sticky-alert-svg(v-else v-html="svgData")
</template>

<script>
  import SmoothReflow from '@shared/components/SmoothReflow';
  import localStorageOptions from '@shared/local-storage-options';

  import { camelizeKeys } from 'humps';

  export default {
    components: {
      SmoothReflow
    },

    data () {
      return {
        svgData: null,
        dismissed: false,

        id: null,
        text: '',
        active: undefined,
        excludeUrls: [],
        buttons: [],
        variant: undefined
      };
    },

    computed: {
      urlNotExcluded () {
        return ![...this.excludeUrls, ...this.buttons.map(button => button.url)].some((url) => window.location.href.indexOf(url) !== -1);
      },

      variantFaIcon () {
        return {
          warning: 'exclamation-triangle'
        }[this.variant];
      }
    },

    mounted () {
      this.cableSubscription = App.cable.subscriptions.create({
        channel: 'AlertChannel'
      }, {
        connected () {
          this.perform('ready');
        },

        received: (data = {}) => {
          data = camelizeKeys(data);

          if (this.id === data.id && (!data.active || data.remove)) {
            data = {};
          }

          const { id = null, active = undefined, excludeUrls = [], buttons = [], variant = undefined, text = '' } = data;

          this.id = id;
          this.active = active;
          this.excludeUrls = excludeUrls;
          this.buttons = buttons;
          this.variant = variant;
          this.text = text;


          const dismissedAlerts = localStorageOptions.fetch('dismissedAlerts');
          this.dismissed = dismissedAlerts[id];
        }
      });
    },

    watch: {
      variant: {
        immediate: true,
        handler () {
          if (!this.variantFaIcon && this.variant) {
            import(/* webpackChunkName: "alert-icons" */ `!!svg-inline-loader!./assets/${this.variant}`).then(module => {
              this.svgData = module.default;
            });
          }
        }
      }
    },

    methods: {
      toggleDismissed () {
        this.dismissed = !this.dismissed;
        localStorageOptions.set('dismissedAlerts', { [this.id]: this.dismissed });
      }
    }
  };
</script>

<style
  scoped
  lang="scss"
>
  .sticky-alert-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 8px 0;
    font-size: 16px;
    line-height: 16px;
  }

  .sticky-alert-container {
    position: relative;
    max-width: 1300px;
    margin: 0 auto;
  }

  .sticky-alert-column {
    margin: 0 10px;
    position: relative;
  }

  .sticky-bookmark-icon {
    padding: 2px 4px 4px;
    color: white;
    position: absolute;
    right: 18px;
    top: 0;
    z-index: 100;
    cursor: pointer;
    width: 32px;
    height: 22px;
    line-height: 16px;
    text-align: center;
    display: block;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);

    &::after {
      $ribbon-height: 8px;
      border-left: 16px solid red;
      border-right: 16px solid red;
      border-bottom: $ribbon-height solid transparent;
      bottom: -$ribbon-height;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      width: 0;
    }
  }

  @mixin themed-alert($color) {
    background: $color;

    &.sticky-bookmark-icon {
      //$color: #10bacc;
      background: $color;
      transition: background .1s;

      &::after {
        border-left-color: $color;
        border-right-color: $color;
      }

      &:hover {
        background: $color;
      }
    }

    .sticky-alert-button:hover {
      color: $color;
    }
  }

  .sticky-alert-warning {
    @include themed-alert(#f0a524);
  }

  .sticky-alert-webinar {
    @include themed-alert(#8142a0);
  }

  .sticky-alert-close {
    color: white;
    opacity: .7;

    &:focus, &:hover {
      opacity: 1;
    }
  }

  .sticky-alert-text {
    vertical-align: middle;
    margin-left: 6px;
  }

  .sticky-alert-fa-icon {
    vertical-align: middle;
  }

  .sticky-alert-svg {
    display: inline-block;

    ::v-deep svg {
      height: 16px;
      vertical-align: middle;
    }
  }

  .flashing {
    animation: flash linear 1.2s infinite;
  }

  @media (max-width: 800px) {
    .sticky-alert-content {
      flex-direction: column;
      text-align: center;
    }

    .sticky-alert-links {
      margin-top: 8px;
      flex-direction: column;

      > * {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // Fade
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .sticky-alert-button {
    padding: 4px 15px;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    margin: 0 10px;
    font-size: .9rem;
    vertical-align: middle;
    transition: all .2s;
    display: inline-block;

    &:hover {
      background: white;
    }

    &:active {
      transform: translate3d(0, 2px, 0);
    }
  }

  a, a:hover, a:focus, a:active {
    text-decoration: none;
  }

  .fade-slide-enter-active, .fade-slide-leave-active {
    transition: opacity .20s, transform .20s;
  }

  .fade-slide-enter, .fade-slide-leave-to {
    opacity: 0;
  }

  .fade-slide-enter {
    transform: translateY(-16px);
  }

  .fade-slide-leave-to {
    transform: translateY(-16px);
  }

  .sticky-alert-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #alert_app {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: $tundora;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  @keyframes flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: .1;
    }
    100% {
      opacity: 1;
    }
  }
</style>