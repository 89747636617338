import AlertApp from '../alert/app.vue'; 
import Vue from 'vue';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('alert_app')) {
    new Vue({
      el: '#alert_app',
      components: { AlertApp },
      template: '<AlertApp/>',
    });
  }
});
